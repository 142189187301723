import Bookr from './Projects/Bookr'
import Ori from './Projects/Ori'
import Xport from './Projects/Xport'

export const data = [
    {
        content: <Bookr />,
        
    },
    {
        content: <Ori />, 
    },
    {
        content: <Xport />,
    },
    {
        content: 'This is Fourth modal',
    },
];




