import BABS from './Projects/BABS'

export const data = [
    {
        content: <BABS />,
        
    },
    
      
];




